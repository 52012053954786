@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';

@mixin break {
  @media screen and (max-width: 600px) {
    @content;
  }
}
.review {
  &__heading {
    margin-bottom: spacing.$m;
  }

  &__modal-text {
    margin-bottom: spacing.$m;
  }

  &__modal-cancel {
    margin-right: spacing.$xs;
  }

  &__description-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$xl;

    @include break {
      flex-direction: column;
    }
  }

  &__description {
    @include break {
      margin-bottom: spacing.$xl;
    }
  }

  &__select {
    align-self: flex-end;
  }

  &__tabs-wrapper {
    margin-bottom: spacing.$l;
    margin-bottom: spacing.$xl;
  }

  &__tabs {
    display: flex;
    gap: spacing.$xs;
  }

  &__tab {
    padding: spacing.$l;
    text-decoration: none;
    white-space: nowrap;
    border: 1px solid light.$ge-divider-default;
    border-radius: corner-radius.$s;
    background-color: light.$surface-primary-default;
    text-align: center;

    &--active {
      color: light.$on-fixed-surface-primary-default;
      background-color: light.$fixed-surface-primary-default;
    }

    @media (max-width: 900px) {
      flex: 1;
    }
  }
}
